@import '../../Global/shared.css';


.linear-progress {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0rem;
}

.linear-progress p {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: rgb(94, 94, 94);
    width: 100%;
}


.error {
    color: var(--error-color);
    animation: fadeIn 300ms forwards;
}

.back-button {
    animation: fadeInExtended 1000ms forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInExtended {
    0% {
        opacity: 0;
        display: none;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

