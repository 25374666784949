@import "../../Global/shared.css";

.error-container {
    width: 100%;
    padding: 0.5rem;
    background-color: var(--error-color);
    font-size: 0.9rem;
    color: white;
    margin-top: 1rem;
    border-radius: 4px;
}